export const statuses = [
  "Created",
  "Pending",
  "Assigned",
  "Completed",
  "Cancelled",
  "On Hold",
  "Rejected",
  "Rescheduled",
  "Waiting for Parts",
];
